import React from "react";

import Neon from "./Neon";

import "./style.css";

export default function Home() {
    return (
        <>
            <div className="BgHomeScreen">
                <div className="full_height HomeScreen">
                    {/* <Neon /> */}
                </div>
            </div>
            <div className="full_height">

            </div>
        </>
    );
}

/*
J’ai 23 ans, je suis développeur web et mobile.
Vous avez besoin d’un site vitrine ?
Vous avez une idée de projet à réaliser ?
Je serai très heureux de discuter avec-vous de votre projet

J’ai 23 ans, je suis développeur web et mobile.
Vous avez pour projet :
Un site vitrine
Une application mobile
Un site marchand
Une demande spécifique
Je serai très heureux de discuter avec-vous de votre projet !

*/