import React from "react";
import { AppBar, Grid, Toolbar, Typography } from "@material-ui/core";

import menu from "../../assets/icons/menu.png";
import "./style.css";

export default function Header() {
    return (
        <AppBar color="transparent" className="header_appbar">
            <Toolbar className="toolbar">
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <Typography className="menu_label">Menu</Typography>
                    </Grid>
                    <Grid item>
                        <img src={menu} alt="Menu icon" />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}