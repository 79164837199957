import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import Home from './components/Home';
import Projects from './components/Projects';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Header from './components/Header';

import './App.css';

function App() {
    return (
        <Router>
            <Header />
            <Switch>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route path="/projects" exact>
                    <Projects />
                </Route>
                <Route path="/contact" exact>
                    <Contact />
                </Route>
                <Route path="/404" exact>
                    <NotFound />
                </Route>
                <Route>
                    <Redirect to="/404"></Redirect>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;


// Notes
// Hover pour le menu
// https://codepen.io/FelixRilling/pen/qzfoc